import React from "react";
import ContentContainer from "../components/container/ContentContainer";
import ContactForm from "../components/forms/ContactForm";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import HeadData from "../data/HeadData";
import TitleDiv from "../components/controls/TitleDiv";
import ButtonAction from "../components/controls/ButtonAction";
import { FaPhoneAlt } from "react-icons/fa";

const ContactCard = ({ person, title, email, phone }) => {
  return (
    <div className="flex flex-col font-sans text-left pl-4">
      {/* <p className="text-lg font-bold">{person}</p> */}
      <h3 className="font-bold">{person}</h3>
      <hr className="w-full mt-3 mb-2 border-t-2 border-blue4"></hr>
      {title && <p>{title}</p>}
      {email && <p>{email}</p>}
      {phone && <p>{phone}</p>}
    </div>
  );
};

const ContactPage = () => {
  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <HeadData title="Contact" />
      <Header />
      <TitleDiv title="Contact" />
      <ContentContainer
        className="flex flex-col-reverse w-full lg:w-1000px mt-10 md-10 md:mb-16 justify-center items-center"
        titleText="Contact National Wood Products – So Cal"
        titleClassName="my-10 px-4 lg:px-0 text-3xl"
      >
        <ButtonAction
          href="tel:(909)-287-7906"
          newWindow={false}
          className="hidden md:block text-white text-lg bg-actionBlue max-h-14 my-12 px-16"
        >
          <FaPhoneAlt className="inline mr-4" />
          {` (909) 287-7906`}
        </ButtonAction>

        <div className="grid grid-cols-1 px-5 lg:px-0 sm:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-24 mb-28">
          <ContactCard
            person="Chris Dennis"
            title="General Manager"
            email="cdennis@nationalwood.com"
            phone="(949) 689 1028"
          />
          <ContactCard
            person="Christina Dennis"
            title="Marketing Manager/Brand Manager"
            email="cadennis@nationalwood.com"
            phone=""
          />
          <ContactCard
            person="Mike Hernandez"
            title="Inside Sales Manager"
            email="mhernandez@nationalwood.com"
            phone="(909) 270 0341"
          />
          <ContactCard
            person="Steve Ceriani"
            title="Outside Sales"
            email="sceriani@nationalwood.com"
            phone="(714) 926 8277"
          />
          <ContactCard
            person="Mark Scholl"
            title="Outside Sales"
            email="mscholl@nationalwood.com"
            phone="(951) 396 0752"
          />
          <ContactCard
            person="Frank Padilla"
            title="Outside Sales"
            email="fpadilla@nationalwood.com"
            phone="(714) 292 8773"
          />
          <ContactCard
            person="Sergio Ruiz"
            title="Inside Sales"
            email="sruiz@nationalwood.com"
            phone="(949) 473 1797"
          />
          <ContactCard
            person="Neftali (Nef) Bravo"
            title="Inside Sales"
            email="nsbravo@nationalwood.com"
            phone="(714) 321 4581"
          />
          <ContactCard
            person="Eric Domanic"
            title="Outside Sales"
            email="edomanic@nationalwood.com"
            phone="(714) 308 6297"
          />
        </div>
        <ContactForm />
        
        <ButtonAction
          href="tel:(909)-287-7906"
          newWindow={false}
          className="block md:hidden text-white text-lg bg-actionBlue max-h-14 my-12 px-16"
        >
          <FaPhoneAlt className="inline mr-4" />
          {` (909) 287-7906`}
        </ButtonAction>
      </ContentContainer>
      <Footer enableRequestQuote={false} />
    </div>
  );
};

export default ContactPage;
